<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-center mb-2">
                    <div class="text-center">
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-2">{{desc1}}<br> {{desc2}}</p>
						<form  @submit.prevent="submit" >
							<button type="button" class="btn btn-primary mr-2 mt-1" @click="search">카테고리 분석 진행하기</button>
						</form>
						<img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                    </div>
                </div>
			</div>
			

			<div class="col-lg-12 d-flex justify-content-between mt-4 ml-3" v-if="showlist">
				<div class="row">
					<b-button variant="outline-success" class="mb-1 mr-2" @click="showtotalcategory">
					전체카테고리수<b-badge variant="success" class="ml-2">{{totalcategory}}</b-badge>
					</b-button>  
					<b-button variant="outline-danger" class="mb-1 mr-2" @click="showreg">
					신규로켓 등록 카테고리<b-badge variant="danger" class="ml-2">{{reg}}</b-badge>
					</b-button> 
					<b-button variant="outline-primary" class="mb-1 mr-2" @click="showincrease">
					최근 상승 카테고리<b-badge variant="primary" class="ml-2">{{increasecategory}}</b-badge>
					</b-button>
					<b-button variant="outline-success" class="mb-1 mr-2" @click="showreg10">
					10주연속 등록 카테고리<b-badge variant="success" class="ml-2">{{reg10}}</b-badge>
					</b-button>
					<!-- <b-button variant="outline-secondary" class="mb-1 mr-2" @click="shownoproduct">
					로켓제품 없는 카테고리<b-badge variant="secondary" class="ml-2">{{noproduct}}</b-badge>
					</b-button> -->
					<b-button variant="outline-danger" class="mb-1 mr-2" @click="showcheck">
					체크한 항목
					</b-button>
					<b-button variant="outline-primary" class="mb-1 mr-2" @click="showmemo">
					메모한 항목
					</b-button>   
				</div> 
				<div>
					<p>last update : {{lastupdate}}</p>
				</div>
			</div>

			<div class="col-lg-12 mt-3" v-if="showlist">
				<div class="table-responsive rounded mb-3">
					<tableList>
						<template v-slot:header>
							<tr class="ligth ligth-data">
								<th>대카테고리</th>
								<th>중카테고리</th>
								<th>소카테고리</th>
								<th>세부카테고리</th>
								<th>링크</th>
								<th>평균판매가</th>
								<th>평균리뷰수</th>
								<th>평균조회수</th>
								<th>광고효율지수</th>
								<th>소싱효율지수</th>
								<!-- <th>신규상품수</th> -->
								<!-- <th>전체대비비율</th> -->
								<th class="text-center" style="max-width:60px;" v-for="(d,index) in categorylists[0].trend" :key="index">
									{{d.date.slice(5)}}
								</th>
								<th v-b-tooltip.top="'확인한 항목을 체크해 주세요. 체크한 항목버튼으로 필터링이 가능합니다.'">항목체크</th>
								<th v-b-tooltip.top="'메모할 내용을 입력해 주세요. 메모한 항목버튼으로 필터링이 가능합니다.'">비고</th>
							</tr>
						</template>
						<template v-slot:body>
							<tr v-for="(categorylist,index) in categorylists" :key="index">
								<td>{{categorylist.ca1}}</td>
								<td>{{categorylist.ca2}}</td>
								<td>{{categorylist.ca3}}</td>
								<td>{{categorylist.ca4}}</td>
								
								<td class="text-center"><a :href="categorylist.링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>
								<td>{{categorylist.price ? categorylist.price.toLocaleString() : categorylist.price}}</td>
								<td>{{categorylist.reviewCnt ? categorylist.reviewCnt.toLocaleString() : categorylist.reviewCnt}}</td>
								<td>{{categorylist.click ? categorylist.click.toLocaleString() : categorylist.click}}</td>
								<td>{{categorylist.adROI ? categorylist.adROI.toLocaleString() : categorylist.adROI}}</td>
								<td>{{categorylist.sourcingRate ? categorylist.sourcingRate.toLocaleString() : categorylist.sourcingRate}}</td>
								<!-- <td>{{categorylist.newqty}}</td> -->
								<!-- <td>{{categorylist.rate}}</td> -->
								
								<td v-for="(d,index) in categorylist.trend" :key="index" data-data="age" data-type="num" class="text-center" :style="d.newqty > 0 ? 'color:blue;font-weight:bolder' : 'color:lightgray'">{{parseInt(d.newqty)}}
									<!-- <i class="dripicons dripicons-arrow-up text-primary" v-if="d.newqty > 0"></i>
									<i class="dripicons dripicons-arrow-down text-secondary" v-if="d.newqty < 0"></i> -->
									<!-- <span class="text-primary" v-if="categorylist.trend > 0">▲</span>
									<span class="text-secondary" v-if="categorylist.trend < 0">▼</span> -->
								</td>
								<td>
									<div class="checkbox d-flex justify-content-center">
										<input type="checkbox" class="checkbox-input" v-model="categorylist.check">
									</div>
								</td>
								<td><input type="text" class="form-control" aria-label="" v-model="categorylist.memo" style="text-align:center;"></td>
							</tr>
						</template>
					</tableList>
				</div>
            <!-- <button v-if="$store.state.user.version == 'premium'" type="button" class="btn btn-primary mr-2 mb-4" @click="download">다운로드</button> -->
			<button type="button" class="btn btn-secondary mr-2 mb-4" @click="savestatus">저장</button>
			<div class="float-right"><input type="text" class="form-control" placeholder="Page검색" v-model="pagenumber" @keyup.enter="pagemv($event)"></div>
			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import { core } from '../../../config/pluginInit'
export default {
	name:'salesstatus',
	data(){
		return{

			reg: 0,
			noproduct:0,
			categorylists:[],
			categorylistsinit:[],
			totalcategory:0,
			increasecategory:0,
			reg10:0,
			// checklist:0,
			// memolist:0,

			salescnt:0,
			importcnt:0,
			salesamount:0,
			supplyamount:0,
			noreview:0,
			productqty:0,
			nostock:0,
			// increview:0,
			// decreview:0,


			title:"카테고리 시장성 / 신규 로켓제품 등록현황",
			desc1:"카테고리 1페이지 기준 시장성 분석 및 지난주에 등록된 카테고리별 신규 로켓제품 등록현황",
			desc2:"신규등록수량은 실제수량과 차이가 있을 수 있으며, 등록비율은 전체 카테고리중 해당 카테고리의 신규로켓제품 등록비율입니다.",
			link:{ name: 'people.addusers'},
			linktext:"판매내역수집방법",

			SKUID:'',
			showspinner: false,
			startdate: this.getTodaywith(),
			enddate: this.getTodaywith(),
			saleslists: [],
			saleslistsinit: [],
			showlist: false,
			lastupdate:'',
			pagenumber: '',

			UserListS:[
				{
					name:'Cliff Hanger',
					email:'cliff@gmail.com',
					company:'Product Manager', 
					group:'Cliff',
					status:'Active',
					store:'1'
				},
				{
					name:'Terry Aki',
					email:'terry@gmail.com',
					company:'Stock CEO', 
					group:'Terry',
					status:'Active',
					store:'2'
				}
			]
		}
	},
	methods: {
		pagemv(event){
			if(event.keyCode === 13){
				// core.mvpage(100)
				core.mvpage(this.pagenumber)
			}
		},
		// changechecklist(){
		// 	this.checklist = this.categorylists.filter(e => e.check).length;
		// },
		// changememolist(){
		// 	this.memolist = this.categorylists.filter(e => e.memo).length;
		// },
		showtotalcategory(){
			this.showlist = false;
			this.categorylists = this.categorylistsinit;
			setTimeout(()=>{this.showlist = true;},10)
		},
		shownoproduct(){
			this.showlist = false;
			this.categorylists = this.categorylistsinit.filter(e => e.nolist == 'O');
			setTimeout(()=>{this.showlist = true;},10)
		},
		showreg(){
			this.showlist = false;
			this.categorylists = this.categorylistsinit.filter(e => e.trend[0].newqty > 0);
			// this.categorylists.sort((a,b) => {return b.newqty - a.newqty})
			setTimeout(()=>{this.showlist = true;},10)
		},	
		showreg10(){
			this.showlist = false;
			this.categorylists = this.categorylistsinit.filter(e => 
				e.trend[0].newqty > 0 
				&& e.trend[1].newqty > 0
				&& e.trend[2].newqty > 0
				&& e.trend[3].newqty > 0
				&& e.trend[4].newqty > 0
				&& e.trend[5].newqty > 0
				&& e.trend[6].newqty > 0
				&& e.trend[7].newqty > 0
				&& e.trend[8].newqty > 0
				&& e.trend[9].newqty > 0);
			// this.categorylists.sort((a,b) => {return b.newqty - a.newqty})
			setTimeout(()=>{this.showlist = true;},10)
		},	
		showincrease(){
			this.showlist = false;
			this.categorylists = this.categorylistsinit.filter(e => e.trend[0].newqty > e.trend[1].newqty);
			// this.categorylists.sort((a,b) => {return b.trend - a.trend})
			setTimeout(()=>{this.showlist = true;},10)
		},	
		showcheck(){
			this.showlist = false;
			this.categorylists = this.categorylistsinit.filter(e => e.check);
			// this.categorylists.sort((a,b) => {return b.trend - a.trend})
			setTimeout(()=>{this.showlist = true;},10)
		},	
		showmemo(){
			this.showlist = false;
			this.categorylists = this.categorylistsinit.filter(e => e.memo);
			// this.categorylists.sort((a,b) => {return b.trend - a.trend})
			setTimeout(()=>{this.showlist = true;},10)
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		search(){
			this.showspinner = true;
			this.showlist = false;
			axios.post('/api/category/getcategorylist',{companyid:this.$store.state.user.companyid})
			.then(result => {
				var obj = {};
				this.lastupdate = result.data.date;
				var arr = [];
				for(var f of result.data.category){
					if(!arr.find(e => e.e.code == f.e.code)){
						arr.push(f)
					}
				}
				var sum = arr.reduce((pv,cv) => {return pv + (cv.newqty ? cv.newqty : 0) },0)
				this.reg = arr.filter(e => e.trend[0].newqty > 0).length;
				console.log(result)
				this.noproduct = arr.filter(e => e.nolist).length;
				this.totalcategory = arr.length;
				// this.checklist = arr.filter(e => e.check).length;
				// this.memolist = arr.filter(e => e.memo).length;
				this.reg10 = arr.filter(e => 
					e.trend[0].newqty > 0
					&& e.trend[1].newqty > 0
					&& e.trend[2].newqty > 0
					&& e.trend[3].newqty > 0
					&& e.trend[4].newqty > 0
					&& e.trend[5].newqty > 0
					&& e.trend[6].newqty > 0
					&& e.trend[7].newqty > 0
					&& e.trend[8].newqty > 0
					&& e.trend[9].newqty > 0
				).length;
				this.increasecategory = arr.filter(e => e.trend[0].newqty > e.trend[1].newqty).length;
				arr.forEach(e => {
					if(e.sales && e.sales.price && e.sales.click && e.sales.reviewCnt){
						obj = {
							ca1:e.e.ca1,
							ca2:e.e.ca2,
							ca3:e.e.ca3,
							ca4:e.e.ca4,
							price:e.sales ? e.sales.price : 0,
							reviewCnt : e.sales ? e.sales.reviewCnt : 0,
							click : e.sales ? e.sales.click : 0,
							adROI : e.sales ? parseInt(e.sales.price*e.sales.reviewCnt/e.sales.click) : 0,
							sourcingRate : e.sales ? parseInt(e.sales.price*e.sales.reviewCnt*e.sales.reviewCnt/e.sales.click) : 0,
							newqty:e.newqty ? e.newqty : 0,
							nolist:e.nolist ? 'O' : '',
							rate : e.newqty ? (e.newqty/sum*100).toFixed(2) + '%' : "0%",
							trend : e.trend ? e.trend : [],
							check : e.check ? e.check : false,
							memo : e.memo ? e.memo : '',
							링크:"https://www.coupang.com/np/categories/" + e.e.code + "?listSize=60&brand=&offerCondition=&filterType=rocket%2Ccoupang_global&isPriceRange=false&minPrice=&maxPrice=&page=1&channel=user&fromComponent=N&selectedPlpKeepFilter=&sorter=bestAsc&filter=&component=" + e.e.code + "&rating=0&rocketAll=true"
						}
						this.categorylistsinit.push(obj)
						this.categorylists.push(obj)
					}
				})
				this.showspinner = false;
				this.showlist = true;
			})
		},
		savestatus(){
			var arr = [];
			this.categorylists.forEach(e => {
				if(e.check || e.memo){
					arr.push({
						companyid:this.$store.state.user.companyid,
						code :e.링크.slice(0,e.링크.search('listSize')-1).replace("https://www.coupang.com/np/categories/",""),
						check : e.check,
						memo : e.memo
					})
				}
			})
			console.log(arr)
			axios.post('/api/category/savecategorymemo',arr)
			.then(result => {
				console.log(result)
				alert(result.data.res);
			})
			.catch(console.log)
		},
		searchresult(){
			this.saleslists = [];
			this.showlist = false;
			var obj = {};
			var d = {};
			var 이전재고 = '';
			var t = {};
			var su = {};
			var 입고수량 = 0;
			if(this.$store.state.sales.length > 0){
				this.$store.state.sales.sort(function(a,b){
					if (a.date < b.date){
						return -1;
					}
					if (a.date > b.date){
						return 1;
					}
					return 0;
				})	
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				this.$store.state.productdb.forEach(e => {
					if(this.$store.state.sales.filter(ele => ele.판매세부내역.find(element => element.sk == e["SKU ID"])).length == this.$store.state.sales.length){
						obj = {
							썸네일 : e.썸네일,
							판매상품명 : e.판매상품명,
							SKUID : e["SKU ID"],
							바코드 : e.바코드,
							발주가능상태 : e.발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : e.판매링크,
							판매세부내역 : []
						}
						if(this.$store.state.sales.length > 1){
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								//console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									//console.log(su)
									if(su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).length > 0){
										입고수량 = su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).reduce((pv,rv) => {return pv + parseInt(rv.수량)},0)
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								if(이전재고 == ''){
									이전재고 = t.s ? t.s : 0;
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s+입고수량 ? t.s+입고수량 : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								이전재고 = d.재고 ? d.재고 : 0;
							})
							var salesqty = 0
							for (var j=1;j<obj.판매세부내역.length;j++){
								salesqty = obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 ? obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 : 0;
								obj.판매세부내역[j].판매 = salesqty ? salesqty : 0;
								obj.판매 += salesqty ? salesqty : 0;
								obj.판매세부내역[j].판매매출 = salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매매출 += salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매세부내역[j].공급매출 = salesqty*e.매입가 ? salesqty*e.매입가 : 0;
								obj.공급매출 += salesqty*e.매입가 ? salesqty*e.매입가 : 0;
							}
						} else {
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								// console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									// console.log(su)
									if(su.importlist.find(element => element.sku번호.toString() == t.sk.toString())){
										입고수량 = parseInt(su.importlist.find(element => element.sku번호.toString() == t.sk.toString()).수량);
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s ? t.s : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.판매 = 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								obj.판매매출 = 0;
								obj.공급매출 = 0;
								setTimeout(() => {이전재고 = d.재고 ? d.재고 : 0},1)
							})
						}
						this.saleslists.push(this.deepClone(obj))
						this.saleslistsinit.push(this.deepClone(obj))
					}
				})
				
				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}			
			
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		}, 		
		todate(date){
			var today = new Date(date); 
			today.setHours(today.getHours() + 9); 
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },		
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},   
		download() {
			console.log(this.categorylists)
			var arr = [];
			this.categorylists.forEach(e => {
				var obj = {
					대카테고리 : e.ca1,
					중카테고리 : e.ca2,
					소카테고리 : e.ca3,
					세부카테고리 : e.ca4,
				}
				e.trend.forEach(ele => {
					obj[ele.date] = ele.newqty;
				})
				obj.바로가기링크 = e.링크,
				obj.항목체크 = e.check ? "Y" : "N",
				obj.비고 = e.memo ? e.memo : '',
				arr.push(this.deepClone(obj))
			})

			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			// console.log(getToday())
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '로켓상품등록현황')
			XLSX.writeFile(workBook, '로켓상품등록현황' + '_' + getToday() + '.xlsx')
		}
	}

}
</script>

<style>
.my-class .dropdown-menu {

max-height: 300px;
width:800px;
overflow-y: auto;
}
.modal-dialog {
    max-width: 90%;
    position: absolute;
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>